import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { globalHistory, useLocation } from '@reach/router';
import axios from 'axios';
import { Script, graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form';
import {
  BackBtn,
  FormStyles,
  ProgressBar,
  ProgressStep,
} from '../../styles/Components/Forms/MobileFormStyles';
import Icon from '../../styles/atoms/icons';
import { FormSpinner } from '../../utils/Spinner';
import { getHubspotUtk } from '../../utils/getHubspotUtk';
import onChangeValidator from '../../utils/onChangeValidator';
import onSubmitValidator from '../../utils/onSubmitValidator';
import { scrollToEl } from '../../utils/scrollTo';
import {
  autocompleteHubspotFields,
  autocompleteOptions,
  convertAutocompleteDataToHubspotFields,
} from './utils/autocomplete';
import setValuesFromParams from './utils/setValuesFromParams';

const MobileDemoForm = ({ formInfo }) => {
  const placesAutocomplete = useRef(null);
  const location = useLocation();
  // AB Test
  const showLocationsField = location.pathname === '/general-f';
  // AB Test
  const showLocationsCheckbox = location.pathname === '/general-c-locations';
  const { heading, subhead, formId } = formInfo;

  const { data } = useStaticQuery(getData);

  const hubspotFormId =
    formId ||
    data?.nodes.filter(node => node?.internalName === 'Demo Form')[0].formId;

  const demoFormUrl = `https://api.hsforms.com/submissions/v3/integration/submit/5424293/${hubspotFormId}`;

  const [step, setStep] = useState(1);
  const [shoptype, setShoptype] = useState([]);
  const [utmParameters, setParameters] = useState({});
  const [hutk, setHutk] = useState('');
  const [payload, setPayload] = useState({
    email: '',
    firstname: '',
    lastname: '',
    company: '',
    phone: '',
    shop_type: '',
  });
  const [invalid, setInvalid] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    company: false,
    shop_type: false,
  });

  useEffect(() => {
    if (showLocationsField) {
      setPayload(prevState => {
        return {
          ...prevState,
          number_of_locations: '',
        };
      });

      setInvalid(prevState => {
        return {
          ...prevState,
          number_of_locations: false,
        };
      });
    }
  }, [showLocationsField]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHutk(getHubspotUtk(document));
      setParameters(JSON.parse(window.sessionStorage.getItem('utms')));
    }
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    const fields = [];
    const formValid = onSubmitValidator(payload, invalid, setInvalid);

    if (!formValid) {
      return false;
    }

    Object.keys(payload).forEach(key => {
      fields.push({ name: key, value: payload[key] });
    });

    Object.keys(utmParameters).forEach(key => {
      utmParameters[key] &&
        fields.push({ name: key, value: utmParameters[key] });
    });

    const pathName = typeof window !== 'undefined' ? window.location.href : '';
    const index = pathName.lastIndexOf('/');
    const trimmedPathName = pathName.substr(index);
    const pageTitle = document.querySelector('title').innerHTML;
    const trimmedPageTitle = pageTitle.split('|')[0];
    const postData = {
      submittedAt: Date.now(),
      fields,
      context: {
        pageUri: `https://www.shopmonkey.io${trimmedPathName}`,
        pageName: `${trimmedPageTitle}`,
        sfdcCampaignId: `${
          hubspotFormId === '453bf9b1-46a5-4398-ab2c-670066688ced'
            ? `7014x000000QP1sAAG`
            : undefined
        }`,
        hutk,
      },
      skipValidation: true,
    };

    axios
      .post(demoFormUrl, postData)
      .then(() => {
        navigate('/thankyou');
      })
      .catch(error => {
        if (error.reponse) {
          console.log(
            `Demo Form Error - ${error.response.data?.errors[0].errorType}`
          );
        } else {
          console.log(`Demo Form Error - ${error.toString()}`);
        }
      });

    return false;
  };

  const handleChange = event => {
    const input = event.target;
    const value = input.type === 'checkbox' ? `${input.checked}` : input.value;

    setPayload(prevState => {
      return {
        ...prevState,
        [event.target.name]: value,
      };
    });
    onChangeValidator(event, invalid, setInvalid);
  };

  const handleReadyForm = () => {
    setTimeout(() => {
      let selectItems = document.querySelectorAll('#hub-form select option');
      const types = [];

      if (!selectItems || selectItems.length === 0) {
        if (document.querySelector('#hub-form iframe')) {
          selectItems = document
            .querySelector('#hub-form iframe')
            .contentDocument.querySelectorAll('select option');
        }
        return;
      }

      selectItems &&
        selectItems.forEach(_option => {
          _option.value !== '' && types.push(_option.value);
        });
      setShoptype(types);
    }, 1000);
  };

  const handleNextButtonClick = () => {
    const stepFields = {
      firstname: payload.firstname,
      lastname: payload.lastname,
      email: payload.email,
    };

    if (!onSubmitValidator(stepFields, invalid, setInvalid)) {
      return false;
    }

    setTimeout(() => scrollToEl('stepform'), 100);
    setStep(2);
    window.setTimeout(initPlacesAutocomplete, 1);
  };

  const initPlacesAutocomplete = () => {
    if (!document.querySelector('#demo-form input[name="company"]')) {
      return;
    }

    if (window.google?.maps?.places?.Autocomplete) {
      const triggerInput = document.querySelector(
        '#demo-form input[name="company"]'
      );

      placesAutocomplete.current = new window.google.maps.places.Autocomplete(
        triggerInput,
        autocompleteOptions
      );

      // Set name value and hidden field values when a place is selected
      placesAutocomplete.current.addListener('place_changed', () => {
        const selectedPlace = placesAutocomplete.current.getPlace();
        const convertedFields = convertAutocompleteDataToHubspotFields(
          selectedPlace
        );
        const hiddenValues = {};

        Object.keys(convertedFields).forEach(key => {
          // Don't populate hidden field if empty to prevent validation error on submit
          if (convertedFields[key]) {
            hiddenValues[key] = convertedFields[key];
          }
        });

        setPayload(prevState => {
          return {
            ...prevState,
            company: selectedPlace.name,
            ...hiddenValues,
          };
        });
      });

      // Clear hidden field values when the company name input value changes
      triggerInput.addEventListener('change', () => {
        setPayload(prevState => {
          const newState = JSON.parse(JSON.stringify(prevState));

          autocompleteHubspotFields.forEach(field => delete newState[field]);

          return newState;
        });
      });
    }
  };

  const handleBeforeSubmit = (form, data) => {
    const findValue = data.find(item => item.name === 'contact_me_via_fax');
    if (!!findValue.value) {
      navigate('/thankyou');
    }
  };

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`}
      />
      <FormStyles className="hero-form" id="stepform">
        <div id="hub-form" className="d-none">
          <HubspotForm
            formId={hubspotFormId}
            loading={<FormSpinner />}
            onFormReady={setValuesFromParams}
            onReady={form => handleReadyForm(form)}
            onBeforeFormSubmit={handleBeforeSubmit}
            onSubmit={handleSubmit}
            portalId={5424293}
          />
        </div>
        {heading && (
          <h2 className="form-title" id="demoform">
            {heading}
          </h2>
        )}
        {subhead && (
          <div
            className="text-subhead"
            dangerouslySetInnerHTML={{ __html: subhead }}
          />
        )}
        <Form noValidate id="demo-form" onSubmit={e => handleSubmit(e)}>
          {step === 1 ? (
            <>
              <Form.Group name="firstname">
                <Form.Label className="required material-label">
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={payload.firstname}
                  required
                  isInvalid={invalid.firstname}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
              <Form.Group name="lastname">
                <Form.Label className="required material-label">
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={payload.lastname}
                  required
                  isInvalid={invalid.lastname}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
              <Form.Group name="email">
                <Form.Label
                  className={`required material-label ${
                    payload.email !== '' ? 'filled' : ''
                  }`}
                >
                  Email address
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  value={payload.email}
                  required
                  isInvalid={invalid.email}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
              <input
                className="w-100 nextbtn mobile-form-next"
                onClick={() => handleNextButtonClick()}
                value="Next"
                type="button"
              />
            </>
          ) : (
            <>
              <Form.Group name="phone">
                <Form.Label className="required material-label">
                  Phone number
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  id="phone"
                  value={payload.phone}
                  required
                  isInvalid={invalid.phone}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
              <Form.Group name="company">
                <Form.Label className="required material-label">
                  Company name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="company"
                  id="company"
                  value={payload.company}
                  required
                  isInvalid={invalid.company}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
              <Form.Group name="shop_type">
                <Form.Label className="required material-label">
                  Shop type
                </Form.Label>
                <FormControl
                  className="w-100"
                  variant="outlined"
                  error={invalid.shop_type}
                >
                  <Select
                    labelId="shoptype-label"
                    id="shop_type"
                    value={payload.shop_type}
                    displayEmpty
                    name="shop_type"
                    label="Shop type"
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled style={{ fontSize: 18 }}>
                      Select shop type
                    </MenuItem>
                    {shoptype.length > 0 &&
                      shoptype.map(type => (
                        <MenuItem
                          style={{ fontSize: 18 }}
                          value={type}
                          key={`type-${type}`}
                        >
                          {type}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Form.Group>
              {showLocationsField && (
                <Form.Group name="number_of_locations">
                  <Form.Label className="required material-label">
                    Number of locations
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="number_of_locations"
                    id="number_of_locations"
                    value={payload.number_of_locations}
                    required
                    isInvalid={invalid.number_of_locations}
                    onChange={e => handleChange(e)}
                  />
                </Form.Group>
              )}
              {showLocationsCheckbox && (
                <Form.Group
                  className="checkbox"
                  name="my_shop_has_5_or_more_locations"
                >
                  <Form.Label className="material-label">
                    <Form.Control
                      checked={payload.my_shop_has_5_or_more_locations}
                      id="my_shop_has_5_or_more_locations"
                      isInvalid={invalid.my_shop_has_5_or_more_locations}
                      name="my_shop_has_5_or_more_locations"
                      onChange={e => handleChange(e)}
                      type="checkbox"
                      value={payload.my_shop_has_5_or_more_locations}
                    />
                    <span>My shop has 5+ locations</span>
                  </Form.Label>
                </Form.Group>
              )}
              <Button
                variant="success"
                type="submit"
                className="w-100 mobile-form-submit"
              >
                Schedule a Demo
              </Button>
            </>
          )}
          <ProgressStep>
            <h3 className="progress-title">Step {step} of 2</h3>
            <ProgressBar>
              <div className="active" style={{ width: `${step * 50}%` }} />
            </ProgressBar>
            {step === 2 && (
              <BackBtn onClick={() => setStep(1)}>
                <Icon id="FiChevronLeft " />
                <span>Back</span>
              </BackBtn>
            )}
          </ProgressStep>
        </Form>
      </FormStyles>
    </>
  );
};

const getData = graphql`
  {
    data: allDatoCmsWebsiteForm {
      nodes {
        formId
        internalName
      }
    }
  }
`;
export default MobileDemoForm;
